<template>
  <header class="e-header">
    <div class="e-brand">
      <Logo />
      <component
        :is="logo"
        class="e-brand-secondary"
      />
    </div>
    <div class="e-header__buttons">

      <template v-if="userInfo">
        <router-link
          v-if="$route.name === 'liveChat' || $route.name === 'chatDialogs'"
          :to="{
            name: 'dialogs'
          }"
          class="e-btn e-btn--primary e-header__button"
        >
          <IconEnvelope />
          <span class="e-mobile-hidden e-tablet-hidden">
            {{ $t('chat.emailSupport') }}
          </span>
        </router-link>

        <template v-else-if="!settings.chatDisabled">
          <button
            v-if="!chatIsOpen"
            :disabled="!chatIsOpen"
            :aria-disabled="!chatIsOpen"
            class="e-btn is-disabled e-header__button"
          >
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
            <chat-icon
              class="e-tablet-show"
              :show-notification="false"
            />
          </button>
          <router-link
            v-else
            :to="{
              name: 'liveChat'
            }"
            :class="[{'needs-chat-interaction': newChatMessage}, 'e-btn', 'e-btn--quaternary', 'e-header__button']"
          >
            <chat-icon
              :show-notification="false"
              :notification-message="$t('chat.newMessage')"
            />
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
          </router-link>
        </template>
      </template>
    </div>

    <nav class="e-header__nav">
      <MetaNav />
    </nav>

    <button
      v-if="!dialogOpen && !chatDialogOpen && !messageEditorOpen"
      type="button"
      name="offcanvas_toggle"
      class="e-offcanvas__toggle"
      @click="toggleOffcanvas"
    >
      <span />
      <span />
      <span />
    </button>
    <router-link
      v-if="!(!chatDialogOpen) && ($route.name === 'chatDialogs' || $route.name === 'chatDialog')"
      :to="{ name: 'chatDialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="!(!dialogOpen && !messageEditorOpen) && ($route.name === 'dialogs' || $route.name === 'dialog' || $route.name === 'newDialog')"
      :to="{ name: 'dialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="!(!dialogOpen && !messageEditorOpen) && ($route.name === 'sentDialog')"
      :to="{ name: 'sent' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
  </header>
</template>

<script>
import VueCookie from 'vue-cookie'
import { mapGetters, mapActions } from 'vuex'
import { userActions } from 'mixins'

import Logo from 'atoms/Logo/Logo'
import Logopausentaste from 'atoms/Logo/LogoPausentaste'
import Logo1000grad from 'atoms/Logo/Logo1000grad'
import MetaNav from 'atoms/Metanav/Metanav'
import ChatIcon from 'atoms/ChatIcon/ChatIcon'

// Icons
import IconExclamation from 'assets/icons/basic-exclamation.svg'
import IconChevronLeft from 'assets/icons/chevron-left.svg'
import IconEnvelope from 'assets/icons/envelope.svg'

// Logos

export default {
  name: 'TheHeader',
  components: {
    IconExclamation,
    IconEnvelope,
    IconChevronLeft,
    MetaNav,
    Logo,
    Logopausentaste,
    Logo1000grad,
    ChatIcon
  },
  mixins: [userActions],

  computed: {
    ...mapGetters(['offcanvasOpen', 'chatDialogOpen', 'dialogOpen', 'messageEditorOpen', 'userInfo', 'theme', 'chatIsOpen', 'newChatMessage', 'settings']),
    logo () {
      if (['pausentaste', '1000grad'].includes(this.theme)) {
        return 'Logo' + this.theme
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setOffcanvas', 'setDialog', 'storeDialog']),
    toggleOffcanvas () {
      this.setOffcanvas(!this.offcanvasOpen)
    },
    logoutAndCatchFire () {
      this.__user__logout(false)
      location.href = 'https://www.startpage.com'
    },
    isThemePausentasteActive () {
      return VueCookie.get('theme') && VueCookie.get('theme') === 'pausentaste'
    },
    isTheme1000GradActive () {
      return VueCookie.get('theme') && VueCookie.get('theme') === '1000grad'
    }
  }
}
</script>

<style lang="scss">
@import '_assets/header';
</style>
