<template>
  <div class="e-supporters">
    <p>{{ $t('system.supportedBy') }}</p>
    <ul>
      <li>
        <IconOTWO class="OTWO" />
      </li>
    </ul>
  </div>
</template>

<script>
import IconOTWO from 'assets/icons_orig/logo-o2.svg'

export default {
  name: 'SupportersBar',
  components: {
    IconOTWO
  }
}
</script>

<style lang="scss">
@import '_assets/supporters';
</style>
