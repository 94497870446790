<template>
  <footer class="e-footer">
    <!-- <span class="e-brand">
      <Logo class="e-brand--white" />
      <LogoOrig class="e-brand--orig" />
    </span> -->
    <supporters class="e-footer__supporters" />
  </footer>
</template>

<script>
import Supporters from 'atoms/Supporters/Supporters'

// Icons
// import Logo from 'assets/icons/logo-ngk-white.svg'
// import LogoOrig from 'assets/icons_orig/logo-ngk-orig.svg'

export default {
  name: 'TheFooter',
  components: {
    // Logo,
    // LogoOrig,
    Supporters
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/footer';
</style>
