<template>
  <nav class="e-offcanvas__nav e-offcanvas__nav--account">
    <h4 class="e-offcanvas__nav-title">
      {{ $t('system.navigation.messagesAndChats') }}
    </h4>
    <ul>
      <li>
        <router-link :to="{ name: 'dialogs' }">
          {{ $t('system.navigation.inbox') }}
        </router-link>
        <div class="e-badge e-offcanvas__nav-badge">
          <el-tooltip
            :content="$t('system.message.unread')"
            class="item"
            effect="dark"
            placement="top"
          >
            <div class="e-badge__item e-badge__item--highlight">
              {{ unreadCount }}
            </div>
          </el-tooltip>

          <el-tooltip
            :content="$t('system.message.total')"
            class="item"
            effect="dark"
            placement="top"
          >
            <div class="e-badge__item">
              {{ threadCount }}
            </div>
          </el-tooltip>
        </div>
      </li>
      <li>
        <router-link :to="{ name: 'sent' }">
          {{ $t('system.navigation.sent') }}
        </router-link>
        <div class="e-badge e-offcanvas__nav-badge">
          <el-tooltip
            :content="$t('system.message.total')"
            class="item"
            effect="dark"
            placement="top"
          >
            <div class="e-badge__item">
              {{ messagesSentCount }}
            </div>
          </el-tooltip>
        </div>
      </li>
      <li v-if="!settings.chatDisabled">
        <router-link :to="{ name: 'chatDialogs' }">
          {{ $t('system.navigation.inboxChat') }}
        </router-link>
        <div class="e-badge e-offcanvas__nav-badge chatmessage-badge">
          <el-tooltip
            :content="$t('system.message.total')"
            class="item"
            effect="dark"
            placement="top"
          >
            <div class="e-badge__item">
              {{ userInfo.chatEventCount }}
            </div>
          </el-tooltip>
        </div>
      </li>
      <li v-if="!settings.chatDisabled && (chatIsOpen || hasActiveChat)">
        <router-link :to="{ name: 'liveChat' }">
          {{ $t('system.navigation.currentChat') }}
          <el-tooltip
            v-if="newChatMessage"
            :content="$t('chat.newMessage')"
            effect="dark"
            placement="top"
          >
            <span class="e-badge e-badge--simple" />
          </el-tooltip>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NavigationMessages',

  computed: {
    ...mapGetters(['dialogs', 'sentMessages', 'userInfo', 'chatIsOpen', 'newChatMessage', 'hasActiveChat', 'settings']),
    threadCount () {
      return this.userInfo.threadCount
    },
    messagesSentCount () {
      return this.userInfo.messagesSentCount
    },
    unreadCount () {
      return this.userInfo.unreadCount
    }
  },
  mounted () {
    if (!this.dialogs) {
      this.getDialogs()
    }

    if (!this.sentMessages) {
      this.getSentMessages()
    }
  },
  methods: mapActions(['getSentMessages', 'getDialogs'])
}
</script>
